
const LocalStorageLang = localStorage?.getItem("i18nextLng") ?? ''
const lang = ['en', 'fr', 'ar'].includes(LocalStorageLang) ? localStorage.getItem("i18nextLng") : "en"
const label = {
    ar: {
        automatic: 'أوتماتيك',
        manual: 'عادي',
        all: "الكل"
    },
    en: {
        automatic: 'Automatic',
        manual: 'Manual',
        all: "All"
    },
    kur: {
        automatic: 'Automatic',
        manual: 'Manual',
        all: "All"
    },
    fr: {
        automatic: 'Automatic',
        manual: 'Manual',
        all: "All"
    }
} as {
    [key: string]: {
        automatic: string
        manual: string
        all: string
    }
}

export const gearbox = [

    {
        label: label[lang ?? "en"]["all"],
        value: ""
    },
    {
        label: label[lang ?? "en"]["automatic"],
        value: "1",
    },
    {
        label: label[lang ?? "en"]["manual"],
        value: "0",
    },
]