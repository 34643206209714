
import { get, post } from 'utils/request'
import { config } from 'utils'
export const general = {
    countries: () => get('/user/countries', {
        headers: {
            Language: 'en',
            'Show-Notification': false,
        },
    }),
    cities: ({ countryID }: { countryID: string }) => get(`/user/cities/${countryID}`, {
        headers: {
            ...config,
            "Show-Notification": false,
        }
    }),
    Createopinion: ({ opinion }: { opinion: string }) => post('/user/general/openion', { openion: opinion }, {
        headers: config
    }),
    help: () => get('/user/general/instruction', {
        headers: {
            ...config,
            "Show-Notification": false,
        }
    }),
    contact: () => get('/user/contact', {
        headers: {
            'Show-Notification': false,
        }
    }),
    offers: () => get('/user/general/offer', {
        headers: {
            'Show-Notification': false,

        }
    }),
    policy: () => get('/user/policy', {
        headers: {
            'Show-Notification': false,
            Language: localStorage?.getItem("i18nextLng") ?? "en"
        }
    }),
    notification: () => get('/user/notifications', {
        headers: {
            ...config,
            "Show-Notification": false,
        }
    })
}