export const company=[
    {
        text:"home",
        path:"/"
    },
    {
        text:"message",
        path:"message"
    },
   
    {
        text:"help",
        path:"help"
    },
    {
        text:"privacy",
        path:"privacyterms"
    },


]