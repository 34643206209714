import create from 'zustand'
import { gearbox } from 'constants/VEHICLE'




type PriceProps = {
    gtp: string
    ltp: string
}
type kilometrageProps = {
    gtk: string
    ltk: string
}
type ModelProps = {
    gtm: string
    ltm: string
}
interface filterProps {
    cities: any[]
    brands: any[]
    gear: any[]
    fuels: any[]
    colors: any[]
    sub_categories: any[]

}
export interface selectedProps {
    clickFilter: boolean
    city_id: string
    brand: string
    auto: boolean | '' | string
    fuel: string
    price: PriceProps
    defaultPrice: number
    page: number
    kilometrage: kilometrageProps
    model: ModelProps
    color: any
    sub_category_id: {
        [key: string]: any
    }
}
interface Iprops {
    filter: filterProps
    selected: selectedProps
    setFilter: (filter: filterProps) => void
    setSelected: (selected: selectedProps) => void
    clearSelected: () => void

}

const filter = {
    brands: [],
    sub_categories: [],
    fuels: [],
    cities: [],
    colors: [],
    gear: gearbox,
}
const selected = {
    clickFilter: false,
    auto: '',
    brand: '',
    city_id: '',
    fuel: '',
    color: '',
    sub_category_id: {
        0: ''
    },
    kilometrage: {
        gtk: '',
        ltk: '',
    },
    page: 1,
    price: {
        gtp: '',
        ltp: ''
    },
    defaultPrice: 0,
    model: {
        gtm: '',
        ltm: ''
    },

}
export const useStore = create<Iprops>()((set) => ({
    filter,
    selected,
    setFilter: (filter) => set({ filter }),
    setSelected: (selected) => set({ selected }),
    clearSelected: () => set({ selected })

}))