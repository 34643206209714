import React, { useEffect } from 'react'
/* Router */
import { Link } from 'react-router-dom'

/* style */
import classes from 'styles/modules/footer.module.css'
/* apis */
import { general } from 'apis/general'
/* constants */
import { company } from 'constants/FOOTER'
import { languages } from 'constants/languages'
/* stores */
import { useStore } from 'stores/social'
/* images */
import logo from 'assets/images/deal-logo.svg'
/* language Library */
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
/*  */
import clsx from 'clsx'


let isFetch = false
export const Footer = () => {
  const initialSocial = useStore(state => state.initialSocial);
  const social = useStore(state => state.social);
  const { t } = useTranslation("user")
  useEffect(() => {
    if (!isFetch) {
      general.contact().then(res => {
        initialSocial(res.data[0])
        isFetch = true
      })
    }
  }, [])
  const changeLanguage = (lng: string) => {

    localStorage.setItem("deal-language", lng)
    i18n.changeLanguage(lng)
    window.location.reload();

  }
  return <footer className={classes.footer}>
    <div className={clsx("container", classes.container)}>
      <div className="logo">    <img src={logo} loading='lazy' alt="Deal" width='150' height='auto' /></div>
      <div className="">
        <span>{t("footer.company")}</span>
        <ul>
          {company.map((item: any, index: number) => <li key={index}>
            <Link to={item.path}> {t(`footer.${item.text}`)}</Link>
          </li>)}
        </ul>
      </div>

      <div className="">
        <span>{t("footer.languages")}</span>
        <ul>
          {

            Object.values(languages)?.map((item: any, index: number) => <li key={index} onClick={() => changeLanguage(item.symbol)} style={{
              cursor: "pointer"
            }}>
              {t(`language.${item.symbol}`)}
            </li>)
          }

        </ul>
      </div>
      <div className="">
        <span>{t("footer.social")}</span>
        <ul>
          <li>
            <a href={`https://${social?.facebook}`} target='_blank' rel="noreferrer">
              {t("footer.facebook")}
            </a>
          </li>
          <li>
            <a href={`https://${social?.twitter}`} target='_blank' rel="noreferrer">
              {t("footer.twitter")}
            </a>
          </li>
          <li>
            <a href={`https://${social?.website}`} target="_blank" rel="noreferrer">
              {t("footer.website")}
            </a>
          </li>
          <li>
            <a href={`https://${social?.instagram}`} target="_blank" rel="noreferrer">
              {t("footer.instagram")}
            </a>
          </li>


        </ul>
      </div>
   
    </div>
    <div className="copyRight">
      <p style={{
        textAlign: "center",
        fontSize:'.9em'
      }}>{t("footer.copyright")}© Deal.com 2022</p>
    </div>
  </footer>

}
